<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row"></div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import KTCard from "@/view/content/Card.vue";
import { mapGetters } from "vuex";

export default {
  name: "Dashboard",
  components: {
    KTCard
  },
  computed: {
    ...mapGetters(["currentUser"]),
    permissions() {
      return this.currentUser.permissions;
    }
  },
  methods: {
    hasPermission(permission) {
      return (
        !this.permissions ||
        (this.permissions &&
          this.permissions.length &&
          this.permissions.some(v => permission === v))
      );
    }
  }
};
</script>
